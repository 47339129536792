// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("dropzone");
import Rails from "@rails/ujs"
global.Rails = Rails;

//import select2 from 'select2';
//import 'select2/dist/css/select2.css';
//window.select2 = select2;

import "smooth-scroll"

import moment from 'moment';
window.moment = moment;

import { Calendar, FullCalendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
window.Calendar = Calendar;

import Typed from 'typed.js';
window.Typed = Typed;

require('jkanban');

import flatpickr from "flatpickr";
window.flatpickr = flatpickr;

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
window.Swal = Swal;



import toastr from 'toastr'
import 'toastr'
window.toastr = toastr;

global.Dropzone = Dropzone;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
